import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

const Header = ({ siteTitle }) => (
  <header className="bg-green-700 flex mb-8">
    <div>
      <svg className="h-24" viewBox="0 0 903 575" version="1.1">
        <polygon id="Triangle" fill="#F6E05E" points="5.00073827e-14 0 903 0 0 575"></polygon>
        <polygon id="Triangle-Copy" fill="#3182CE" points="2.97386097e-14 0 537 0 0 527"></polygon>
      </svg>
    </div>
    <div
      style={{
        margin: `0 auto`,
        maxWidth: 960,
        padding: `1.45rem 1.0875rem`,
      }}
    >
      <h1>
        <Link
          to="/"
          style={{
            color: `white`,
            textDecoration: `none`,
          }}
        >
          {siteTitle}
        </Link>
      </h1>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
